import './App.css';

function App() {
  return (
    <div className="App">
      FD Studio
            是影视行业创意阶段<a href='https://dash.fdstudio.app'>数字化管理工作台</a>，帮助您在影视准备阶段与团队进行剧本、角色、分镜、场景和道具的编辑和设定，一切都在线上进行，而不是在纸张或者电子文档传来传去，您可随时记录针对某个角色或者场景的便笺，并与团队一起讨论，
            您可以上传任意多的图片和文件来丰富您的设定，您还可以借助AI工具，润色您的剧本、对话，生成分镜头甚至动态分镜（视频），同时我们还提供了洞察工具，可深入分析您的镜头和剧本。使用FD
            Studio，您的影视创作效率将得到极大的提升
    </div>
  );
}

export default App;
